Px.Editor.PdfElement = class PdfElement extends Px.Editor.BaseElementComponent {

  template() {
    const element = this.data.element;
    const r = this.renderChild;

    return Px.template`
      <g class="px-pdf-element"
         data-element-id="${element.unique_id}"
         data-selected="${this.isSelected}"
         transform="${this.transformAttribute}"
         pointer-events="${this.pointerEventsAttribute}"
         ${this.clipPathAttribute}>

        ${Px.if(this.data.render_content, () => {
          return Px.template`
            <g pointer-events="${this.pointerEventsAttribute}" opacity="${element.opacity}">
              <image width="${element.width}"
                      height="${element.height}"
                      image-rendering="optimizeQuality"
                      fill="#000"
                      preserveAspectRatio="none"
                      xlink:href="${this.src}"
              />
            </g>

            ${Px.if(this.isLoading, () => {
              return r(Px.Editor.ElementIcon, 'loading-indicator', this.loadingIndicatorProps);
            })}

            ${Px.if(this.isFailed, () => {
              return Px.template`
                <rect width="${element.width}"
                      height="${element.height}"
                      opacity="0.5"
                      stroke-width="${this.inSvgUnits(1)}"
                      stroke="#ff0000"
                      fill-opacity="0"
                />
                ${r(Px.Editor.ElementIcon, 'failed-indicator', this.failedIndicatorProps)}
              `;
            })}
          `;
        })}

        ${Px.if(this.data.render_controls, () => {
          return Px.template`
            ${this.selection_outline_template()}
            ${this.data.preview_mode ? '' : this.renderEditControls()}
          `;
        })}
      </g>
    `;
  }

  constructor(props) {
    super(props);

    this.registerReaction(() => this.data.element.pdf.id, () => this.state.cached_src = null, {
      name: 'Px.Editor.PdfElement::clearCachedSrcReaction'
    });

    this.registerReaction(() => {
      return this.hiresSrc;
    }, src => {
      if (src === null) {
        this.state.image_status = 'failed';
      } else {
        this.state.image_status = 'loading';
        if (src) {
          const image = new Image();
          image.onload = () => {
            this.state.image_status = 'loaded';
          };
          image.onerror = () => {
            this.state.image_status = 'failed';
          };
          image.src = src;
        }
      }
    }, {
      fireImmediately: true,
      name: 'Px.Editor.PdfElement::loadImageReaction'
    });
  }

  static get properties() {
    return {
      image_status: {type: 'str', std: 'loading'},  // one of 'loading', 'loaded', 'failed'
      cached_src: {type: 'str', std: null}
    };
  }

  static get computedProperties() {
    return Object.assign(super.computedProperties, {
      isLoading: function() {
        return this.state.image_status === 'loading';
      },
      isLoaded: function() {
        return this.state.image_status === 'loaded';
      },
      isFailed: function() {
        return this.state.image_status === 'failed';
      },
      pointerEventsAttribute: function() {
        if (this.data.preview_mode) {
          return 'none';
        }
        if (Px.config.advanced_edit_mode) {
          return 'auto';
        }
        return this.data.element.edit ? 'auto' : 'none';
      },
      imageSrcSize: function() {
        const element = this.data.element;
        const max_dim =  Math.max(element.width, element.height);
        return this.data.scale * max_dim * (window.devicePixelRatio || 1);
      },
      hiresSrc: function() {
        const element = this.data.element;
        // "Freeze" the image while resizing/zooming  so that images do not
        // annoyingly start refreshing while manipulation is in progress.
        if (this.state.cached_src &&
            (element.is_resizing || this.data.store.ui.is_zooming)) {
          return this.state.cached_src;
        }
        const src = element.pdf.src({
          size: this.imageSrcSize,
          page: element.page_number
        });
        // We cannot modify cached_src inside the computed function, so do it async.
        requestAnimationFrame(() => this.state.cached_src = src);
        return src;
      },
      src: function() {
        if (this.isLoading && this.state.cached_src) {
          return this.state.cached_src;
        }
        return this.hiresSrc;
      },
      loadingIndicatorProps: function() {
        return {
          store: this.data.store,
          element: this.data.element,
          scale: this.data.scale,
          icon: Px.Editor.ImageElement.icons.loading_indicator,
          title: Px.t('Loading...'),
          position: 'center',
          width: 40 * this.iconScale,
          height: 20 * this.iconScale
        };
      },
      failedIndicatorProps: function() {
        return {
          store: this.data.store,
          element: this.data.element,
          scale: this.data.scale,
          icon: Px.Editor.ImageElement.icons.failed_indicator,
          title: Px.t('Preview failed to load'),
          position: 'center',
          width: 38 * this.iconScale,
          height: 38 * this.iconScale
        };
      }
    });
  }

};
