Px.Editor.ThemeTemplatesProjectStore = class ThemeTemplatesProjectStore extends Px.Editor.BaseProjectStore {

  constructor(theme_store, image_store, pdf_store) {
    super();
    this.theme_store = theme_store;
    this.image_store = image_store;
    this.pdf_store = pdf_store;
    this.theme_id = theme_store.theme_id;
    this.name = 'Templates';
  }

  get actions() {
    return Object.assign(super.actions, {

      load: function() {
        const xhr = $j.getJSON(this.apiURL());
        xhr.done(data => {
          mobx.runInAction(() => {
            this.unit = data.unit;
            if (data.minimum_dpi) {
              this.minimum_dpi = data.minimum_dpi;
            }
            data.templates.forEach(template => {
              template.print_page.images.forEach(image => {
                const img_id = `db:${image.id}`;
                if (!this.image_store.get(img_id)) {
                  this.image_store.register(img_id, image);
                }
              });
              template.print_page.pdfs.forEach(pdf => {
                const pdf_id = `db:${pdf.id}`;
                if (!this.pdf_store.get(pdf_id)) {
                  this.pdf_store.register(pdf_id, pdf);
                }
              });
            });
            mobx.when(() => this.theme_store.loaded, () => {
              this.loaded = true;
              this.setLayoutAndStoreSavedSnapshot(this.makeLayoutJSON(data));
            });
          });
        });
        return new Promise((resolve, reject) => {
          xhr.done(resolve).fail(jxhr => {
            this.loaded = true;
            if (jxhr.status === 403) {
              let default_text = "You don't have permission to open this project.\n";
              default_text += "Perhaps you forgot to log in?";
              reject(Px.t('project permission error', default_text));
            } else {
              let default_text = "An error occured while loading the project.\n";
              default_text += "You can try reloading the page.\n";
              default_text += "If the problem persists, please contact support.";
              reject(Px.t('project load error', default_text));
            }
          });
        });
      },

      save: function() {
        // Refuse to save if the project contains any local images that haven't finished uploading yet.
        if (this.local_images.length) {
          alert(Px.t('Cannot save: some images are still uploading. Try again later.'));
          return Promise.reject(new Error('Images still uploading'));
        }
        this.saving = true;
        const pages = {};
        const promise = fetch(this.apiURL(), {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          },
          body: $j.param({book: this.data_for_save_endpoint})
        }).then(response => {
          if (response.ok) {
            return response.json();
          } else {
            throw new Error(`HTTP Error; Status: ${response.status}`);
          }
        }).then(json => {
          // TODO: Be more inteligent; don't replace the entire thing.
          mobx.runInAction(() => {
            this.setLayoutAndStoreSavedSnapshot(this.makeLayoutJSON(json));
            this.saving = false;
          });
        }).catch(() => {
          this.saving = false;
        });
        return promise;
      }

    });
  }

  apiURL() {
    return `/v1/themes/${this.theme_id}.json`;
  }

  availableBleedValuesForPage(page) {
    // Use an object instead of array to ensure uniqueness.
    const available_bleeds = {};
    this.theme_store.set_definitions.forEach(set_def => {
      set_def.pages.forEach(page_def => {
        if (page_def.template_names.includes(page.name)) {
          available_bleeds[JSON.stringify(page_def.bleed)] = page_def.bleed;
        }
      });
    });
    return Object.values(available_bleeds).sort();
  }

  availableMarginValuesForPage(page) {
    // Use an object instead of array to ensure uniqueness.
    const available_margins = {};
    this.theme_store.set_definitions.forEach(set_def => {
      set_def.pages.forEach(page_def => {
        if (page_def.template_names.includes(page.name)) {
          available_margins[JSON.stringify(page_def.margin)] = page_def.margin;
        }
      });
    });
    return Object.values(available_margins).sort();
  }

  // -------
  // Private
  // -------

  makeLayoutJSON(response_data) {
    return response_data.templates.map(template => {
      const page = template.print_page;
      const bleed_values = this.availableBleedValuesForPage(page);
      const margin_values = this.availableMarginValuesForPage(page);
      page._virtual_bleed = bleed_values[0] || [0, 0, 0, 0];
      page._virtual_margin = margin_values[0] || [0, 0, 0, 0];
      return {
        print_page: [page],
        center_caption: page.name,
        editor: true
      };
    });
  }

};
