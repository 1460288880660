Px.Editor.LayoutModel = class LayoutModel extends Px.Editor.PageModel {

  static fromJSON(json, params, main_store) {
    const layout = super.fromJSON(json, params);
    layout.main_store = main_store;
    layout.tags = json.tags;
    return layout;
  }

  static get properties() {
    return Object.assign(super.properties, {
      tags: {std: mobx.observable.array()}
    });
  }

  get bleed() {
    const selected_page = this.main_store.selected_page;
    return selected_page ? selected_page.bleed : [0, 0, 0, 0];
  }

  set bleed(val) {
    // Ignore; bleed value is always taken from selected page.
  }

  applicableToPage(page, options_store) {
    const options = options_store.combined_available_options;
    const has_layout_substitutions = options.some(option => {
      return option.values && option.values.some(value => {
        const layout_substitutions = value.element_substitutions && value.element_substitutions.layouts;
        return layout_substitutions && Object.keys(layout_substitutions).some(page_names => {
          const pages = page_names.split(',').map(n => n.trim());
          return page.pageNameMatches(pages);
        });
      });
    });
    return !has_layout_substitutions && this._nameValidOnPage(page) && this._sizeValidOnPage(page);
  }

  // -------
  // Private
  // -------

  _nameValidOnPage(page) {
    return page.pageNameMatches(this.pages);
  }

  _sizeValidOnPage(page) {
    var width_percent = page.width * LayoutModel.SIZE_TOLERANCE;
    var height_percent = page.height * LayoutModel.SIZE_TOLERANCE;
    var page_width = page.width - page.binding_width;

    var in_width_tolerance = (this.width >= page_width - width_percent) &&
                             (this.width <= page_width + width_percent);
    var in_height_tolerance = (this.height >= page.height - height_percent) &&
                              (this.height <= page.height + height_percent);

    return in_width_tolerance && in_height_tolerance;
  }

};

Px.Editor.LayoutModel.SIZE_TOLERANCE = 0.10;  // 10% tolerance on the size of the layout vs the page
