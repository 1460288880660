Px.Editor.InlinePageElement = class InlinePageElement extends Px.Editor.BaseElementComponent {

  template() {
    const element = this.data.element;
    const r = this.renderChild;

    return Px.template`
      <g class="px-ipage-element"
         data-element-id="${element.unique_id}"
         data-selected="${this.isSelected}"
         transform="${this.transformAttribute}"
         pointer-events="${this.pointerEventsAttribute}"
         ${this.clipPathAttribute}>

        ${Px.if(this.data.render_content, () => {
          return Px.template`
            ${Px.if(this.showLoadingIcon, () => {
              return r(Px.Editor.ElementIcon, 'loading-indicator', this.loadingIndicatorProps);
            })}

            <defs>
              <clipPath id="${this.clipPathId}">
                <rect x="0"
                      y="0"
                      width="${element.width}"
                      height="${element.height}"
                      rx="${element.radius}"
                      ry="${element.radius}"
                />
              </clipPath>
              <mask id="${this.maskId}">
                ${Px.if(element.mask, () => {
                  return Px.template`
                    <image x="0"
                          y="0"
                          width="${element.width}"
                          height="${element.height}"
                          preserveAspectRatio="none"
                          xlink:href="${this.maskSrc}"
                    />
                  `;
                }).else(() => {
                  return Px.template`
                    <rect x="0"
                          y="0"
                          width="${element.width}"
                          height="${element.height}"
                          fill="#fff"
                    />
                  `;
                })}
              </mask>
              ${Px.if(this.hasShadow, () => {
                // SVG filter dimensions are defined in fractions (where width="1" equals width="100%"),
                // so we need to calculate the correct values based on absolute values on shadow stdev and element size.
                const stdev_fraction_x = element.shadow_stdev / element.width;
                const stdev_fraction_y = element.shadow_stdev / element.height;
                return Px.template`
                  <filter id="${this.filterId}"
                          x="${-3 * stdev_fraction_x}"
                          y="${-3 * stdev_fraction_y}"
                          width="${1 + 6 * stdev_fraction_x}"
                          height="${1 + 6 * stdev_fraction_y}">
                    <feDropShadow dx="${element.shadow_ox}"
                                  dy="${element.shadow_oy}"
                                  stdDeviation="${element.shadow_stdev}"
                                  flood-color="${element.shadow_color}"
                                  flood-opacity="${element.shadow_opacity}"
                                  color-interpolation-filters="sRGB"
                    />
                  </filter>
                `;
              })}
           </defs>

            ${Px.if(this.hasShadow, () => {
              // We could apply the drop shadow filter directly on the original <g> element below,
              // but unfortunately there's a bug in Safari where filtered elements can look horribly
              // pixelated in some circumstances: https://stackoverflow.com/q/14664407/51397
              // To work around this, we render the <g> with the filter applied,
              // and then render another copy without the filter on top of it.
              return Px.template`
                <use href="#${this.uniqueId}" filter="url(#${this.filterId})" />
              `;
            })}

            <g id="${this.uniqueId}">
              <svg style="overflow:hidden"
                  width="${element.width}"
                  height="${element.height}"
                  mask="url(#${this.maskId})"
                  clip-path="url(#${this.clipPathId})">
                <g pointer-events="${this.pointerEventsAttribute}" opacity="${element.opacity}">
                  ${Px.if(this.embeddedPage && !this.isCircularReference, () => {
                    return Px.template`
                      <g transform="${this.embeddedPageTransformAttribute}">
                        ${r(Px.Editor.Page, `page-${this.embeddedPage.id}`, this.embeddedPageProps)}
                      </g>
                    `;
                  }).else(() => {
                    return Px.template`
                      <rect width="${element.width}"
                            height="${element.height}"
                            stroke-width="0"
                            fill="#fab" />
                      ${r(Px.Editor.ElementIcon, 'missing-page-icon', this.missingPageIconProps)}
                    `;
                  })}
                </g>
              </svg>
            </g>
          `;
        })}

        ${Px.if(this.data.render_controls, () => {
          return Px.template`
            ${this.selection_outline_template()}
            ${(this.data.preview_mode || !Px.config.advanced_edit_mode) ? '' : this.renderEditControls()}
          `;
        })}
      </g>
    `;
    }

  constructor(props) {
    super(props);

    this.registerReaction(() => {
      const mask_image = this.data.element.mask_image;
      return mask_image && mask_image.id;
    }, () => {
      this.state.cached_mask_src = null;
    }, {
      name: 'Px.Editor.InlinePageElement::clearCachedMaskSrcReaction'
    });

    this.registerReaction(() => {
      const mask_image = this.data.element.mask_image;
      return mask_image && mask_image.id && this.maskHiresSrc;
    }, src => {
      if (src === null) {
        this.state.mask_image_status = 'loaded';
      } else {
        const image = new Image();
        this.state.mask_image_status = 'loading';
        image.onload = () => {
          this.state.mask_image_status = 'loaded';
        };
        image.onerror = () => {
          this.state.mask_image_status = 'failed';
        };
        image.src = src;
      }
    }, {
      fireImmediately: true,
      name: 'Px.Editor.InlinePageElement::loadMaskImageReaction'
    });
  }

  static get properties() {
    return {
      mask_image_status: {type: 'str', std: 'loading'},  // one of 'loading', 'loaded', 'failed'
      cached_mask_src: {type: 'str', std: null}
    };
  }

  static get computedProperties() {
    return Object.assign(super.computedProperties, {
      uniqueId: function() {
        return `px-${this._component_id}-${this.data.element.unique_id}`;
      },
      clipPathId: function() {
        return `px-clip-path-${this.uniqueId}`;
      },
      maskId: function() {
        return `px-mask-${this.uniqueId}`;
      },
      filterId: function() {
        return `px-shadow-filter-${this.uniqueId}`;
      },
      isMaskLoading: function() {
        return this.state.mask_image_status === 'loading';
      },
      isMaskLoaded: function() {
        return this.state.mask_image_status === 'loaded';
      },
      isMaskFailed: function() {
        return this.state.mask_image_status === 'failed';
      },
      showLoadingIcon: function() {
        return this.isMaskLoading;
      },
      embeddedPage: function() {
        return this.data.element.embedded_page;
      },
      embeddedPageProps: function() {
        const element = this.data.element;
        const scale = this.data.scale;
        return {
          page: this.embeddedPage,
          available_width: element.embedded_page_dimensions[0] * scale,
          available_height: element.embedded_page_dimensions[1] * scale,
          preserve_aspect_ratio: !element.stretch,
          preview_mode: this.data.preview_mode,
          hide_placeholders: this.data.hide_placeholders,
          render_content: this.data.render_content,
          render_controls: this.data.render_controls,
          transparent_bgcolor: this.data.store.theme.background_transparent,
          page_stack: this.data.page_stack
        };
      },
      embeddedPageTransformAttribute: function() {
        const element = this.data.element;
        const w = element.embedded_page_dimensions[0];
        const h = element.embedded_page_dimensions[1];
        const x = w * element.left/100;
        const y = h * element.top/100;
        const inverse_scale = this.data.scale ? (1 / this.data.scale) : 0;
        const transform = [
          `translate(${(element.width - w)/2}, ${(element.height - h)/2})`,
          `translate(${x}, ${y})`,
          `scale(${inverse_scale}, ${inverse_scale})`
        ].join(' ');
        return transform;
      },
      isCircularReference: function() {
        if (this.data.page_stack.includes(this.embeddedPage)) {
          const src = this.embeddedPage.id || this.embeddedPage.template;
          console.warn(`Circular reference detected when rendering inline page: ${src}`);
          return true;
        }
        return false;
      },
      missingPageIconProps: function() {
        const element = this.data.element;
        return {
          store: this.data.store,
          element: element,
          scale: this.data.scale,
          icon: InlinePageElement.icons.missing_page_indicator,
          title: Px.t('Page not found: {{page}}'.replace('{{page}}', element.id || element.template)),
          position: 'center',
          width: 38 * this.iconScale,
          height: 38 * this.iconScale
        };
      },
      loadingIndicatorProps: function() {
        return {
          store: this.data.store,
          element: this.data.element,
          scale: this.data.scale,
          icon: InlinePageElement.icons.loading_indicator,
          title: Px.t('Loading...'),
          position: 'center',
          width: 40 * this.iconScale,
          height: 20 * this.iconScale
        };
      },
      maskSrc: function() {
        const mask_image = this.data.element.mask_image;
        if (!mask_image) {
          return null;
        }
        if (this.isMaskLoading) {
          return this.state.cached_mask_src || mask_image.preview;
        }
        return this.maskHiresSrc;
      },
      maskHiresSrc: function() {
        // "Freeze" the image while resizing/zooming  so that images do not
        // annoyingly start refreshing while manipulation is in progress.
        if (this.state.cached_mask_src &&
            (this.data.element.is_resizing || this.data.store.ui.is_zooming)) {
          return this.state.cached_mask_src;
        }
        const params = {
          size: this.maskSrcSize
        };
        const src = this.data.element.mask_image.src(params);
        // We cannot modify cached_src inside the computed function, so do it async.
        setTimeout(() => this.state.cached_mask_src = src);
        return src;
      },
      maskSrcSize: function() {
        const size = this.data.scale * (Math.max.apply(null, this.data.element.embedded_page_dimensions));
        return size * (window.devicePixelRatio || 1);
      },
      hasShadow: function() {
        return this.data.element.shadow_opacity > 0;
      }
    });
  }

  // --------------
  // Event handlers
  // --------------

  dragCrop(evt) {
    const oevt = evt.originalEvent;
    let pageX = 'pageX' in oevt ? oevt.pageX : oevt.targetTouches[0].pageX;
    let pageY = 'pageY' in oevt ? oevt.pageY : oevt.targetTouches[0].pageY;

    const element = this.data.element;
    const drag_origin = this.data.store.ui.current_drag.origin;

    // The change of x and y in the user's coordinate system.
    let dx = this.inSvgUnits(pageX - drag_origin.pageX);
    let dy = this.inSvgUnits(pageY - drag_origin.pageY);

    let origin_left = drag_origin.element_left;
    let origin_top = drag_origin.element_top;

    // The change of x and y in the element's (possibly rotated)
    // coordinate system.
    const rotated = Px.Util.rotatePoint(dx, dy, element.absolute_rotation);
    const shift_x = rotated[0];
    const shift_y = rotated[1];

    const page_width = element.embedded_page_dimensions[0];
    const page_height = element.embedded_page_dimensions[1];

    const transposed = Px.Util.rotatePoint(shift_x, shift_y, 0);
    const left = (transposed[0] * 100/page_width) + drag_origin.element_left;
    const top = (transposed[1] * 100/page_height) + drag_origin.element_top;

    element.update({
      left: left,
      top: top
    });
  }

};

Px.Editor.InlinePageElement.icons = {
  missing_page_indicator: '<svg width="38" height="38" viewBox="-6 -6 38 38"><g stroke-width="1" fill="none" stroke="#ff0000" opacity="0.5" fill-rule="evenodd"><g transform="translate(-1123.000000, -19.000000)"><g transform="translate(1096.000000, 0.000000)"><g transform="translate(28.000000, 20.000000)"><polyline stroke-linejoin="round" points="0 7 7 7 7 0"></polyline><polygon points="24 24 0 24 0 7 7 0 24 0"></polygon></g></g></g><line x1="32" y1="-6" x2="-6" y2="32" /></g></svg>',
  loading_indicator: '<svg width="6" height="2" viewBox="0 0 6 2" class="px-image-loading-indicator"><circle class="px-circ-2" cx="3" cy="1" r="0.5" fill="#000000" opacity="0.5" stroke="#ffffff" stroke-width="0.25"></circle><circle class="px-circ-1" cx="1" cy="1" r="0.5" fill="#000000" opacity="0.5" stroke="#ffffff" stroke-width="0.25"></circle><circle class="px-circ-3" cx="5" cy="1" r="0.5" fill="#000000" opacity="0.5" stroke="#ffffff" stroke-width="0.25"></circle></svg>'
};
