Px.Editor.BarcodeElement = class BarcodeElement extends Px.Editor.BaseElementComponent {

  template() {
    const element = this.data.element;
    return Px.template`
      <g class="px-barcode-element"
         data-element-id="${element.unique_id}"
         data-selected="${this.isSelected}"
         transform="${this.transformAttribute}"
         pointer-events="${this.pointerEventsAttribute}"
         ${this.clipPathAttribute}>
        ${Px.if(this.data.render_content, () => {
          return Px.template`
            <g pointer-events="${this.pointerEventsAttribute}">
              <g pointer-events="${this.pointerEventsAttribute}" opacity="${element.opacity}">
                <rect width="${element.width}" height="${element.height}" stroke-width="0" fill="#fff" />
                ${BarcodeElement.demo_barcode.map(point => {
                  return Px.template`
                    <rect x="${point[0] * this.barcodeScale}"
                          y="0"
                          width="${point[1] * this.barcodeScale}"
                          height="${element.height}"
                          fill="#000"
                    />
                  `;
                })}
              </g>
            </g>
          `;
        })}
        ${Px.if(this.data.render_controls, () => {
          return Px.template`
            ${this.selection_outline_template()}
            ${(this.data.preview_mode || !Px.config.advanced_edit_mode) ? '' : this.renderEditControls()}
          `;
        })}
      </g>
    `;
  }

  static get computedProperties() {
    return Object.assign(super.computedProperties, {
      barcodeScale: function() {
        return this.data.element.width / 224;
      }
    });
  }

};

// Demo barcode that encodes the string "Pixfizz". Generated with http://lindell.me/JsBarcode/generator/
Px.Editor.BarcodeElement.demo_barcode = [[0, 4], [6, 2], [12, 2], [22, 6], [30, 6], [38, 4], [44, 2], [54, 4], [60, 2], [66, 8], [78, 2], [84, 2], [88, 2], [92, 4], [104, 2], [110, 2], [120, 4], [126, 2], [132, 4], [138, 8], [148, 4], [154, 4], [160, 8], [170, 4], [176, 2], [184, 4], [192, 2], [198, 4], [208, 6], [216, 2], [220, 4]];
