Px.Editor.LayoutStore = class LayoutStore extends Px.BaseStore {

  constructor(project_id, theme_id, main_store, image_store, pdf_store) {
    super();
    this.project_id = project_id;
    this.theme_id = theme_id;
    this.main_store = main_store;
    this.image_store = image_store;
    this.pdf_store = pdf_store;
  }

  static get properties() {
    return {
      loaded: {std: false},
      layouts: {std: mobx.observable.array()}
    };
  }

  static get computedProperties() {
    return {
      image_elements: function() {
        const images = [];
        this.layouts.forEach(layout => {
          layout.image_elements.forEach(image => {
            images.push(image);
          });
        });
        return images;
      },
      text_elements: function() {
        const texts = [];
        this.layouts.forEach(layout => {
          layout.text_elements.forEach(text => {
            texts.push(text);
          });
        });
        return texts;
      },
      _image_elements_by_id: function() {
        const images = {};
        this.image_elements.forEach(image => {
          if (!images[image.id]) {
            images[image.id] = [];
          }
          images[image.id].push(image);
        });
        return images;
      }
    };
  }

  get actions() {
    return {
      load: function() {
        const xhr = $j.getJSON(this.layoutsAPIEndpoint());
        xhr.done(data => {
          mobx.runInAction(() => {
            data.forEach(layout => {
              layout.images.forEach(image => {
                const img_id = `db:${image.id}`;
                if (!this.image_store.get(img_id)) {
                  this.image_store.register(img_id, image);
                }
              });
              layout.pdfs.forEach(pdf => {
                const pdf_id = `db:${pdf.id}`;
                if (!this.pdf_store.get(pdf_id)) {
                  this.pdf_store.register(pdf_id, pdf);
                }
              });
              const model = Px.Editor.LayoutModel.fromJSON(
                layout,
                {
                  image_store: this.image_store,
                  pdf_store: this.pdf_store
                },
                this.main_store
              );
              // Make sure all layout elements have `clone_id` set to `null`, otherwise weird things
              // can happen when you use the layout on a two-page-spread enabled projects.
              model.forEachElement(element => {
                element.clone_id = null;
              });
              this.layouts.push(model);
            });
            this.loaded = true;
          });
        });
      },

      onNewSelectedPage: function(page) {
        this.layouts.forEach(layout => {
          const virtual_bleed = page && page.has_bleed ? page.bleed.toJS() : null;
          layout._virtual_bleed = virtual_bleed;
        });
      }
    };
  }

  get(layout_id) {
    var layout_id_str = String(layout_id);
    for (var i = 0; i < this.layouts.length; i++) {
      if (String(this.layouts[i].id) === layout_id_str) {
        return this.layouts[i];
      }
    }
    return null;
  }

  forEachElement(fn) {
    this.layouts.forEach(layout => {
      layout.forEachElement(fn);
    });
  }

  getImageElementsByImageId(id) {
    return this._image_elements_by_id[id] || [];
  }

  // -------
  // Private
  // -------

  layoutsAPIEndpoint() {
    let url = `/v1/themes/${this.theme_id}/layouts.json?substitutions=true`;
    if (this.project_id !== null) {
      url += `&book_id=${this.project_id}`;
    }
    return url;
  }

};
