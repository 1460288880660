// Defines methods and computed properties shared among all Element types.
Px.Editor.BaseElementComponent = class BaseElementComponent extends Px.Util.mixin(
  Px.Editor.BaseComponent,
  Px.Editor.SVGElementMixin
) {

  selection_outline_template() {
    const element = this.data.element;
    return Px.template`
      <g>
        <rect class="${this.outlineCssClasses}"
              x="${this.selectionOutlineX}"
              y="${this.selectionOutlineY}"
              width="${this.selectionOutlineWidth}"
              height="${this.selectionOutlineHeight}"
              stroke-width="${this.selectionOutlineStrokeWidth}"
              stroke="${this.selectionOutlineColor}"
              opacity="${this.selectionOutlineOpacity}"
              cursor="${this.cursorIcon}"
              stroke-dasharray="${this.selectionOutlineDashArray}"
              fill-opacity="0"
              pointer-events="${this.pointerEventsAttribute}"
              ${this.pointerEventHandlers}
              ${Px.if(element.radius, () => {
                return Px.template`
                  rx="${element.radius}"
                  ry="${element.radius}"
                `;
              })}
        />
        ${Px.if(this.secondarySelectionOutlineStrokeWidth, () => {
          return Px.template`
            <rect x="${this.selectionOutlineX}"
                  y="${this.selectionOutlineY}"
                  width="${this.selectionOutlineWidth}"
                  height="${this.selectionOutlineHeight}"
                  stroke-width="${this.secondarySelectionOutlineStrokeWidth}"
                  stroke="#fff"
                  opacity="${this.selectionOutlineOpacity}"
                  fill-opacity="0"
                  pointer-events="none"
                  ${Px.if(element.radius, () => {
                    return Px.template`
                      rx="${element.radius}"
                      ry="${element.radius}"
                    `;
                  })}
            />
          `;
        })}
      </g>
    `;
  }

  constructor(data) {
    super(data);

    this._drag_raf = null;
    this._end_drag_with_undo = null;

    this.dragElement = this.dragElement.bind(this);
    this.releaseElement = this.releaseElement.bind(this);
    this.disableScrollHandler = this.disableScrollHandler.bind(this);
  }

  static getClass(type) {
    const classes = {
      'image': Px.Editor.ImageElement,
      'text':  Px.Editor.TextElement,
      'barcode': Px.Editor.BarcodeElement,
      'qrcode': Px.Editor.QrCodeElement,
      'score': Px.Editor.ScoreElement,
      'group': Px.Editor.GroupElement,
      'grid': Px.Editor.GridElement,
      'calendar': Px.Editor.CalendarElement,
      'pdf': Px.Editor.PdfElement,
      'ipage': Px.Editor.InlinePageElement
    };
    const cls = classes[type];
    if (!cls) {
      throw new Error(`Unknown element type: ${type}`);
    }
    return cls;
  }

  get dataProperties() {
    return {
      element: {required: true},
      scale: {required: true},
      page_clip_path_id: {required: true},
      store: {required: true},
      preview_mode: {std: false},
      mobile_mode: {std: false},
      hide_placeholders: {std: false},
      render_content: {std: true},
      render_controls: {std: true},
      page_stack: {required: true}
    };
  }

  static get computedProperties() {
    return {
      // This clips controls of unselected elements so that they don't go outside the page borders.
      // Allowing invisible controls extend outside page borders is no good when there is more than
      // one page in the set, because controls from one page may overlap elements from the other page,
      // and then you never know what you are going to select when you click somewhere.
      // But for the selected element, we do want the control layer to overflow, since that's basically
      // the main purpose of the separate control layer -- ability to interact with selected element's controls
      // even if they stick outside of the page.
      clipPathAttribute: function() {
        if (this.data.render_controls && !this.isSelected) {
          return Px.raw(`clip-path="url(#${this.data.page_clip_path_id})"`);
        }
        return '';
      },
      isHovered: function() {
        const element = this.data.element;
        return element.is_hovered || (element.two_page_spread_clone && element.two_page_spread_clone.is_hovered);
      },
      isSelected: function() {
        const element = this.data.element;
        const selected_element = this.data.store.selected_element;
        if (!selected_element) {
          return false;
        }
        return element === selected_element || element.two_page_spread_clone === selected_element;
      },
      isInSelectedGroup: function() {
        const element = this.data.element.group;
        const selected_element = this.data.store.selected_element;
        if (!(element && selected_element)) {
          return false;
        }
        return element === selected_element || element.two_page_spread_clone === selected_element;
      },
      isInCurrentSelection: function() {
        return this.isElementInCurrentSelection(this.data.element);
      },
      isParentGroupInCurrentSelection: function() {
        return this.isElementInCurrentSelection(this.data.element.group);
      },
      cursorIcon: function() {
        const element = this.data.element;
        if (this.data.preview_mode || !this.isSelected) {
          return 'auto';
        } else if (this.isCropping) {
          return 'move';
        } else if (this.data.store.ui.current_drag) {
          return 'grabbing';
        } else if (this.isSelected && this.data.element.edit && this.data.element.move) {
          return 'grab';
        } else {
          return 'auto';
        }
      },
      showSelectionHighlight: function() {
        return this.data.mobile_mode || !this.data.preview_mode;
      },
      // Returns a string representing the transform value that should be
      // applied to the element in order to draw it correctly inside its container.
      // The returned string is of the form: "matrix(a, b, c, d, e, f)".
      transformAttribute: function() {
        const M = this.data.element.transform_matrix;
        const attr = `matrix(${ M.join(',') })`;
        return attr;
      },
      pointerEventsAttribute: function() {
        if (this.data.preview_mode) {
          return 'none';
        }
        if (Px.config.advanced_edit_mode) {
          return 'auto';
        }
        return this.data.element.edit ? 'auto' : 'none';
      },
      pointerEventHandlers: function() {
        const handlers = [
          'data-onmousedown="grabElement"',
          'data-touchstart="grabElement"',
          'data-onmouseenter="hoverElement"',
          'data-onmouseleave="unhoverElement"'
        ];
        return Px.raw(handlers.join(' '));
      },
      selectionOutlineX: function() {
        return 0;
      },
      selectionOutlineY: function() {
        return 0;
      },
      selectionOutlineWidth: function() {
        return this.data.element.width;
      },
      selectionOutlineHeight: function() {
        return this.data.element.height;
      },
      selectionOutlineColor: function() {
        // TODO: Different color when selecting subcomponents of a group.
        if (this.data.mobile_mode) {
          return 'var(--mobile-editor-selection-color)';
        } else if (this.editableOutline) {
          return 'var(--editor-editable-area-highlight-color)'
        } else {
          return 'var(--editor-selection-color)';
        }
      },
      selectionOutlineStrokeWidth: function() {
        if (this.isInSelectedGroup) {
          return this.inSvgUnits(1);
        } else if (this.selectionOutlineDashArray !== 0) {
          return this.inSvgUnits(2);
        } else {
          return this.inSvgUnits(3);
        }
      },
      selectionOutlineOpacity: function() {
        if (!this.data.store.ui.show_element_selection_outline) {
          return 0;
        } else if (this.isSelected || this.isHovered || this.isInSelectedGroup ||
            this.isInCurrentSelection || this.editableOutline) {
          return 1;
        } else {
          return 0;
        }
      },
      selectionOutlineDashArray: function() {
        if (this.data.element.group || this.isInSelectedGroup || this.isInCurrentSelection || this.editableOutline) {
          return this.inSvgUnits(3);
        } else if (this.isHovered && !this.isSelected) {
          return this.inSvgUnits(3);
        } else {
          return 0;
        }
      },
      // The secondary selection rect is just a detail of the implementation of the black/white/black
      // selection outline. The secondary outline is the white line in the middle.
      secondarySelectionOutlineStrokeWidth: function() {
        if (this.selectionOutlineDashArray === 0 && this.selectionOutlineStrokeWidth === this.inSvgUnits(3)) {
          return this.inSvgUnits(1);
        } else {
          return 0;
        }
      },
      editableOutline: function() {
        return (
          (Px.config.highlight_editable || this.data.mobile_mode) &&
            !this.data.preview_mode &&
            !this.isSelected &&
            !this.isInCurrentSelection &&
            this.data.element.placeholder &&
            this.data.element.edit
        );
      },
      alignmentSnapTolerance: function() {
        return this.inSvgUnits(Px.Editor.BaseElementModel.ELEMENT_ALIGNMENT_SNAP_TOLERANCE_PIXELS);
      },
      outlineCssClasses: function() {
        var classes = ['elementOutline'];
        if (this.editableOutline) {
          classes.push('editableOutline');
        }
        if (this.isSelected) {
          classes.push('selected');
        }
        return classes.join(' ');
      },
      iconScale: function() {
        const store = this.data.store;
        let scale = 1;
        if (store.ui.editor_mode === 'mobile') {
          if (store.mobile.view_mode === 'project' || store.mobile.view_mode === 'cut-prints-quantity-selection') {
            scale = 0.5;
          }
        }
        return scale;
      }
    };
  }

  renderEditControls() {
    return Px.template`
      ${this.renderRotateIcon()}
      ${this.renderResizeHandles()}
      ${this.renderTopControls()}
      ${this.renderCropIcon()}
    `;
  }

  renderResizeHandles() {
    if (!this.data.store.ui.show_element_resize_handles) {
      return '';
    }
    return this.renderChild(Px.Editor.ElementResizeHandles, 'resize-handles', {
      element: this.data.element,
      scale: this.data.scale
    });
  }

  renderTopControls() {
    return this.renderChild(Px.Editor.ElementEditIconsContainer, 'top-controls', {
      element: this.data.element,
      scale: this.data.scale
    });
  }

  renderRotateIcon() {
    return this.renderChild(Px.Editor.ElementRotateIcon, 'rotate-icon', {
      element: this.data.element,
      scale: this.data.scale
    });
  }

  renderCropIcon() {
    return this.renderChild(Px.Editor.ElementCropIcon, 'crop-icon', {
      element: this.data.element,
      scale: this.data.scale,
      grabElement: this.grabElement.bind(this)
    });
  }

  renderBleedWarning() {
    if (this.data.store.crop_bleed) {
      return '';
    }
    return this.renderChild(Px.Editor.ElementBleedWarning, 'bleed-warning', {
      element: this.data.element,
      scale: this.data.scale
    });
  }

  disableScrollHandler(evt) {
    evt.preventDefault();
  }

  isElementInCurrentSelection(element) {
    const selected_element = this.data.store.selected_element;
    if (!(element && selected_element && selected_element.type === 'selection')) {
      return false;
    }
    return selected_element.hasElement(element) || selected_element.hasElement(element.two_page_spread_clone);
  }

  // --------------
  // Event handlers
  // --------------

  selectElement(evt) {
    evt.stopPropagation();
    const store = this.data.store;
    let element = this.data.element;
    if (element.group && !(Px.config.advanced_edit_mode && evt.altKey)) {
      element = element.group;
    }
    if (this.data.mobile_mode) {
      store.mobile.animate(() => {
        store.selectSet(element.page.set);
        store.selectElement(element);
        store.ui.resetZoom();
      });
    } else {
      mobx.runInAction(() => {
        store.selectSet(element.page.set);
        if (Px.config.advanced_edit_mode && evt.shiftKey) {
          store.addOrRemoveFromElementSelection(element);
        } else {
          store.selectElement(element);
        }
      });
    }
  }

  hoverElement(evt) {
    if (Px.urlQuery()['hover'] !== 'true') {
      return;
    }
    const element = this.data.element;
    mobx.runInAction(() => {
      element.is_hovered = true;
      if (element.group) {
        element.group.is_hovered = true;
      }
    });
  }

  unhoverElement(evt) {
    if (Px.urlQuery()['hover'] !== 'true') {
      return;
    }
    const element = this.data.element;
    mobx.runInAction(() => {
      element.is_hovered = false;
      if (element.group) {
        element.group.is_hovered = false;
      }
    });
  }

  grabElement(evt) {
    if (evt.type === 'mousedown' && evt.which !== 1) {
      return;
    }
    if (evt.type === 'touchstart' && evt.originalEvent.touches.length > 2) {
      return;
    }
    const coords = Px.Util.pointerCoords(evt)
    const store = this.data.store;
    let element = this.isInCurrentSelection ? store.selected_element : this.data.element;
    if (this.data.element.group && !(Px.config.advanced_edit_mode && evt.altKey)) {
      element = this.data.element.group;
    }

    if (store.selected_element &&
        !(store.selected_element === element || store.selected_element.two_page_spread_clone === element)) {
      // When a selected element is present underneath another element, we want pointer events
      // targeting the selected element's edit/control icons to pass through the top element.
      let proxy_to_element = null;
      const nodes = document.elementsFromPoint(coords.clientX, coords.clientY);
      for (const node of nodes) {
        if (node.matches('.px-control-handle')) {
          const element_node = node.closest('g[data-element-id]');
          if (element_node) {
            const element_id = element_node.getAttribute('data-element-id');
            if (store.selected_element.unique_id === element_id ||
                (store.selected_element.two_page_spread_clone &&
                 store.selected_element.two_page_spread_clone.unique_id == element_id)) {
              proxy_to_element = node.closest('.px-control-component');
              evt.target = evt.currentTarget = node;
            }
          }
          break;
        }
      }
      if (proxy_to_element) {
        // Ugly: grabs reference to the control Component attached to the control handle element.
        proxy_to_element.__px_component.grabHandle(evt);
        return;
      }
    }

    if (!element.edit && !Px.config.advanced_edit_mode) {
      return;
    }
    // If any element other than the body is currently focused, deselect it.
    if (document.activeElement && document.activeElement !== document.body) {
      document.activeElement.blur();
    }

    evt.stopPropagation();
    evt.preventDefault();

    if (store.ui.editor_mode === 'mobile' && !store.ui.element_cropping_mode) {
      // In mobile mode drag & drop is only supported in cropping mode.
      // Touchdown on an editable element when not in cropping mode only selects the element,
      // no matter whether the element is movable or not.
      store.selectElement(element);
      return;
    }

    if (evt.shiftKey) {
      store.addOrRemoveFromElementSelection(this.data.element);
      return;
    }

    if (!element.move) {
      // When in cropping mode, we should allow grabbing the image, even if it is not movable.
      if (!(element.type === 'image' && element === store.selected_element && store.ui.element_cropping_mode)) {
        // Oterwise, if this element is not movable, select it and abort.
        store.selectElement(element);
        return;
      }
    }

    store.grabElement(element, coords.pageX, coords.pageY);

    this._end_drag_with_undo = store.undo_redo.beginWithUndo({
      label: (store.ui.element_cropping_mode ? 'crop ' : 'move ') + element.type,
      set_id: store.selected_set.id
    });

    const $doc = $j(document);
    if (evt.type === 'mousedown') {
      $doc.on('mousemove', 'svg.px-page', this.dragElement);
      $doc.on('mouseup', this.releaseElement);
    } else {
      if (evt.originalEvent.touches.length === 2 && store.ui.element_cropping_mode) {
        this.startCropPinch(evt);
      }
      $doc.on('touchmove', 'svg.px-page', this.dragElement);
      $doc.on('touchend touchcancel', this.releaseElement);
    }
  }

  dragElement(evt) {
    if (this._drag_raf) {
      cancelAnimationFrame(this._drag_raf);
    }
    this._drag_raf = requestAnimationFrame(() => {
      if (this.data.store.ui.element_cropping_mode) {
        this.dragCrop(evt);
      } else {
        this.dragMove(evt);
      }
    });
  }

  releaseElement(evt) {
    if (this._drag_raf) {
      cancelAnimationFrame(this._drag_raf);
      this._drag_raf = null;
    }
    const $doc = $j(document);
    if (evt.type === 'mouseup') {
      $doc.off('mousemove', this.dragElement);
      $doc.off('mouseup', this.releaseElement);
    } else {
      $doc.off('touchmove', this.dragElement);
      $doc.off('touchend touchcancel', this.releaseElement);
    }
    mobx.runInAction(() => {
      this.data.store.releaseElement(this.data.element);
      this._end_drag_with_undo();
    });
  }

  dragMove(evt) {
    const ui_store = this.data.store.ui;
    const coords = Px.Util.pointerCoords(evt);
    let element;
    if (this.isInCurrentSelection || this.isParentGroupInCurrentSelection) {
      element = this.data.store.selected_element;
    } else if (this.data.element.group && !(Px.config.advanced_edit_mode && evt.altKey)) {
      element = this.data.element.group;
    } else {
      element = this.data.element;
    }
    const drag_origin = this.data.store.ui.current_drag.origin;

    let dx = this.inSvgUnits(coords.pageX - drag_origin.pageX);
    let dy = this.inSvgUnits(coords.pageY - drag_origin.pageY);

    if (this.data.store.isAutorotatedCutPrint(element.page)) {
      // Swap dx and dy.
      [dx, dy] = [-dy, dx];
    }

    // Accumulated rotation of parent groups, but not including element's own rotation.
    const angle = element.absolute_rotation - element.rotation;
    [dx, dy] = Px.Util.rotatePoint(dx, dy, angle);

    if (evt.shiftKey) {
      if (Math.abs(dx) >= Math.abs(dy)) {
        dy = 0;
      } else {
        dx = 0;
      }
    }

    let new_x = drag_origin.element_x + dx;
    let new_y = drag_origin.element_y + dy;

    const snap_point = element.translationSnapPoint(new_x, new_y, ui_store.grid_spec);

    if (snap_point.x !== null && Math.abs(snap_point.x - new_x) < this.alignmentSnapTolerance) {
      new_x = snap_point.x;
    }
    if (snap_point.y !== null && Math.abs(snap_point.y - new_y) < this.alignmentSnapTolerance) {
      new_y = snap_point.y;
    }

    element.update({x: new_x, y: new_y});
  }

};
