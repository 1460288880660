Px.Editor.DbPdfModel = class DbPdfModel extends Px.Editor.BasePdfModel {

  static get computedProperties() {
    return Object.assign(super.computedProperties, {
      dimensions: function() {
        return [this.data.width || 0, this.data.height || 0];
      }
    });
  }

  src(params) {
    params = _.clone(params);
    const base_url = this.baseUrl(params);
    if (base_url === null) {
      return null;
    }

    let url = base_url;

    if (params.page) {
      if (base_url.match('/fz/')) {  // Fizztore URL.
        const parts = url.split('/~/');
        parts[0] += `/-/page/${params.page}`;
        url = parts.join('/~/');
      } else {
        // TODO: Remove after everything is in Fizztore.
        url += `&page=${params.page}`;
      }
    }

    return url;
  }

  baseUrl(params) {
    const size = this.getOptimalSize(params.size);
    const thumb = _.findWhere(this.data.thumbnails, {size: size});

    let url = thumb && thumb.url;

    if (!url) {
      console.error(`Could not find PDF thumbnail URL: ${this.id} (size: ${size})`);
      url = null;
    }

    return url;
  }

  getOptimalSize(size) {
    size = size || 0;
    const standard_sizes = DbPdfModel.STANDARD_SIZES;
    let optimal = standard_sizes[0];
    let i = 1;
    while (optimal < size && i < standard_sizes.length) {
      optimal = standard_sizes[i];
      i++;
    }
    return optimal;
  }

};

// Keep this in sync with Pdf.STANDARD_THUMBNAIL_SIZES.
Px.Editor.DbPdfModel.STANDARD_SIZES = [150, 250, 600, 900, 1200];
